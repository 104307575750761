var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-6 py-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "mb-6 mt-15" },
            [
              _c(
                "v-col",
                { staticClass: "pt-0 mb-lg-0 mb-10", attrs: { lg: "4" } },
                [
                  _c("card-thirty-day-health", {
                    attrs: { metricsEndDateMoment: _vm.metricsEndDateMoment },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pt-0 mb-lg-0 mb-10", attrs: { lg: "4" } },
                [
                  _c("card-thirty-day-perf", {
                    attrs: { metricsEndDateMoment: _vm.metricsEndDateMoment },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pt-0 mb-lg-0", attrs: { lg: "4" } },
                [_c("card-open-cases")],
                1
              ),
            ],
            1
          ),
          _c("v-row", [_c("v-col", { attrs: { cols: "12" } })], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }