<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <!-- sales by country 
      <v-row class="mb-12">
        <v-col md="12">
          <sales-country></sales-country>
        </v-col>
      </v-row>
      -->
      
      <v-row class="mb-6 mt-15">
        <v-col lg="4" class="pt-0 mb-lg-0 mb-10">
          <card-thirty-day-health :metricsEndDateMoment="metricsEndDateMoment"></card-thirty-day-health>
        </v-col>
        <v-col lg="4" class="pt-0 mb-lg-0 mb-10">
          <card-thirty-day-perf :metricsEndDateMoment="metricsEndDateMoment"></card-thirty-day-perf>
        </v-col>
        <v-col lg="4" class="pt-0 mb-lg-0">
          <card-open-cases></card-open-cases>
        </v-col>
      </v-row>
     
      
      <v-row >
        <v-col cols="12">
          <!-- <site-container v-bind:componentProps="componentProps"></site-container> -->
          <!-- <component v-bind:is="componentName" v-bind:componentProps="componentProps" /> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SalesCountry from "./Widgets/SalesCountry.vue";
import CardWebsiteViews from "./Widgets/CardWebsiteViews.vue";
import CardDailySales from "./Widgets/CardDailySales.vue";
import CardCompletedTasks from "./Widgets/CardCompletedTasks.vue";
import HighAlertSite from './Widgets/HighAlertSite.vue';
import CardOpenCases from './Widgets/CardOpenCases.vue';
import DatatableSites from '../Applications/Widgets/DatatableSites.vue';
import SiteContainer from './Site/SiteContainer';
import CardThirtyDayHealth from './Widgets/CardThirtyDayHealth.vue';
import CardThirtyDayPerf from './Widgets/CardThirtyDayPerf.vue';
import ProblemInverter from './Widgets/ProblemInverter.vue';
import CardMissingKwhData from './Widgets/CardMissingKwhData.vue';
import MediumAlertSite from './Widgets/MediumAlertSite.vue';
export default {
  name: "Dashboard",
  components: {
    SalesCountry,
    CardWebsiteViews,
    CardDailySales,
    CardCompletedTasks,
    HighAlertSite,
    CardOpenCases,
    DatatableSites,
    CardThirtyDayHealth,
    CardThirtyDayPerf,
    ProblemInverter,
    CardMissingKwhData,
    SiteContainer,
    MediumAlertSite
  },
  beforeMount() {
    this.yesterdayMoment = this.$moment().subtract(1, "days");
    this.metricsEndDateMoment = this.$moment().subtract(1, "days");
    this.displayMetricsEndDate = this.metricsEndDateMoment.format("MM/DD/YYYY");
    this.checkFrequenciesToDisplay = this.defaultCheckFrequenciesToDisplay();
  },
  data() {
    return {
      componentProps: {},
      componentName: "DatatableSites",
      checkFrequenciesToDisplay: null,
      displayAllCheckFrequencies: false,
      yesterdayMoment: null,
      metricsEndDateMoment: null,
      displayMetricsEndDate: null,
      allCheckFrequencies: [
        {
          value: "daily",
          text: "Daily",
        },
        {
          value: "weekly",
          text: "Weekly",
        },
        {
          value: "monthly",
          text: "Monthly",
        },
      ],
    };
  },
  methods: {
    defaultCheckFrequenciesToDisplay() {
      const metricsEndDateIsFirstOfMonth =
        this.metricsEndDateMoment.date() === 1;
      const metricsEndDateIsFirstOfWeek = this.metricsEndDateMoment.day() === 4;

      let checkFrequencies = ["daily"];

      if (metricsEndDateIsFirstOfMonth) {
        checkFrequencies.push("monthly");
      }

      if (metricsEndDateIsFirstOfWeek) {
        checkFrequencies.push("weekly");
      }

      return checkFrequencies;
    },
  },
};
</script>
